// Layout
@main-layout-bottom-margin: 24px;
@main-layout-top-margin: 24px;
@main-layout-left-margin: 48px;
@main-layout-right-margin: 48px;

main.site-content.-main {
  margin-bottom: @main-layout-bottom-margin;
  margin-top: @main-layout-top-margin;
  margin-left: @main-layout-left-margin;
  margin-right: @main-layout-right-margin;
  background: @white;
}

main.site-content.-main.-dashboard {
  background: transparent;
  padding: 0 24px 0 47px !important;
  margin-right: 24px;
  margin-left: 0;
}

section.site-layout {
  background: @background-color_25;
  overflow-x: scroll;
}

.site-layout {
  background: @background-color_25;
  // height: 100vh;
}

.site-content.-main {
  width: auto;
  // overflow-y: scroll;
  border-radius: 4px;
}
