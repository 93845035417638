.order-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @secondary-color;
  padding: 8px 16px;

  > .transaction {
    color: @white;
    font-size: 24px;
    font-weight: bold;
  }

  > .transaction > .id {
    color: @primary-color;
  }

  > .print {
    margin-left: 16px;
    margin-right: auto;
  }
}
